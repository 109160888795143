<template>
  <b-container class="news">
    <Bloco :showDestaque="showDestaque" v-if="active" />
    <img src="https://arquivos.aquidauana.ms.gov.br/pub/banner-destaque.jpg" v-else />
    <div class="card-area" v-loading="loading" v-if="active">
      <div
        class="card-news"
        v-for="noticia in noticias.data"
        :key="noticia.not_cod"
        @click.prevent="getPage(noticia.not_cod, slugify(noticia.not_titulo))"
      >
        <NewsCard image title>
          <img
            :src="(noticia.imagem && noticia.imagem.length && noticia.imagem[0]) || ''"
            alt=""
            slot="image"
            @error="placeholder"
          />
          <div class="text" slot="body">
            <span class="news-time">{{
              noticia.not_data | formatDate('LL')
            }}</span>
            <span class="news-title">{{ noticia.not_titulo }}</span>
          </div>
        </NewsCard>
      </div>
    </div>
    <div v-if="Object.keys(noticias).length > 0 && active">
      <b-pagination
        align="center"
        v-model="noticias.current_page"
        pills
        :total-rows="noticias.total"
        @change="handleChangePage"
        :per-page="noticias.per_page"
      ></b-pagination>
    </div>
  </b-container>
</template>

<script>
import Bloco from '../components/News/Bloco.vue';
import NewsCard from '@/components/News/NewsCards/NewsCard.vue';

export default {
  name: 'news',
  components: { Bloco, NewsCard },
  data() {
    return {
      noticias: [],
      loading: false,
      active: false
    };
  },
  methods: {
    placeholder(e) {
      e.target.src = require(`@/assets/images/placeholder-412.jpg`);
    },
    async getNoticias(pg = 1) {
      this.loading = true;
      this.noticias = [];
      window.scrollTo({
        top: 0,
      });
      const res = await this.$axios.get(`noticias?page=${pg}`).catch((e) => {
        console.error('Noticias', e);
      });
      if (res.status === 200) {
        this.noticias = res.data;
      }
      this.loading = false;
    },
    handleChangePage(pg) {
      this.getNoticias(pg);
    },
    slugify(text) {
      return text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-');
    },
    getPage(id, title) {
      this.$router.push(`${`/noticia/${id}/${title}`}`);
    },
    showDestaque(noticia) {
      this.getPage(noticia.not_cod, this.slugify(noticia.not_titulo));
    },
  },
  mounted() {
    this.getNoticias();
  },
};
</script>

<style lang="scss" scoped>
.news {
  padding: 0;
  background-color: var(--background-color-primary);
  overflow: hidden;

  .card-area {
    margin: 30px 10px 30px 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    .card-news {
      padding: 0 20px 20px 0;
      cursor: pointer;
    }
  }
}
</style>
